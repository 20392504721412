import styled from "styled-components";
import Link from "components/cms/link";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";
import YoutubeIcon from "icons/youtube-icon-white.svg";
import InstagramIcon from "icons/instagram-icon-white.svg";
import LinkedinIcon from "icons/linkedin-icon-white.svg";
import FacebookIcon from "icons/facebook-icon-white.svg";
import CloseIcon from "icons/close-icon-white.svg";

export const Wrapper = styled.div`
  position: relative;
  padding: ${calculateResponsiveSize(75)} ${calculateResponsiveSize(72)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: ${calculateResponsiveSize(30)};
  right: ${calculateResponsiveSize(183)};
  width: ${calculateResponsiveSize(20)};
  height: ${calculateResponsiveSize(25)};
  background: url(${CloseIcon}) center no-repeat;
  border: 0;
  outline: none;
  z-index: 1;
  cursor: pointer;
  ${mediaMax("desktopSm")} {
    right: 5vw;
    width: 20px;
    height: 25px;
  }
`;

export const MenuLinks = styled.div`
  display: ${({ $hideMobile }) => ($hideMobile ? `none` : `flex`)};
  flex-direction: column;
  height: 100%;
  justify-content: center;
  ${mediaMax("desktopSm")} {
    display: flex;
    height: auto;
    margin-top: auto;
  }
`;

export const MenuLink = styled(Link)`
  margin-bottom: ${calculateResponsiveSize(10)};
  color: #fff;
  font-family: "Macklin Display", sans-serif;
  font-size: ${calculateResponsiveSize(50)};
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  ${mediaMax("desktopSm")} {
    font-size: 36px;
    margin-bottom: 9px;
    ${({ $mobileSeconaryLink }) =>
    $mobileSeconaryLink &&
    `
      margin-bottom: 9px;
      font-family: 'Helvetica Now', sans-serif;
      font-size: 12px;
      line-height :1.5;  
      letter-spacing: 1px;
      text-transform: uppercase;
  `}
  }
`;

export const SocialLinks = styled.div`
  position: absolute;
  bottom: ${calculateResponsiveSize(50)};
  left: ${calculateResponsiveSize(72)};
  display: flex;
  align-items: center;
  ${mediaMax("desktopSm")} {
    margin-top: 16px;
    position: static;
  }
`;

export const SocialLink = styled.a`
  margin-right: ${calculateResponsiveSize(52)};
  display: block;

  ${mediaMax("desktopSm")} {
    margin-right: 32px;
  }
  &.youtube {
    width: ${calculateResponsiveSize(26)};
    height: ${calculateResponsiveSize(18)};
    background: url(${YoutubeIcon}) center center no-repeat;
    background-size: contain;
    ${mediaMax("desktopSm")} {
      width: 26px;
      height: 18px;
    }
  }
  &.facebook {
    width: ${calculateResponsiveSize(11)};
    height: ${calculateResponsiveSize(22)};
    background: url(${FacebookIcon}) center center no-repeat;
    background-size: contain;
    ${mediaMax("desktopSm")} {
      width: 11px;
      height: 22px;
    }
  }
  &.instagram {
    width: ${calculateResponsiveSize(22)};
    height: ${calculateResponsiveSize(22)};
    background: url(${InstagramIcon}) center center no-repeat;
    background-size: contain;
    ${mediaMax("desktopSm")} {
      width: 22px;
      height: 22px;
    }
  }
  &.linkedin {
    width: ${calculateResponsiveSize(20)};
    height: ${calculateResponsiveSize(22)};
    background: url(${LinkedinIcon}) center center no-repeat;
    background-size: contain;
    ${mediaMax("desktopSm")} {
      width: 20px;
      height: 22px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;
