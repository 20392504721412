import styled from "styled-components";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";
import YoutubeIcon from "icons/youtube-icon-black.svg";
import InstagramIcon from "icons/instagram-icon-black.svg";
import LinkedinIcon from "icons/linkedin-icon-black.svg";
import FacebookIcon from "icons/facebook-icon-black.svg";
import Link from "components/cms/link";

export const Wrapper = styled.footer`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${calculateResponsiveSize(26)} 9vw;
  z-index: 1;
  background-color: #fff;

  ${mediaMax("desktop")} {
    padding: 26px 9vw;
  }
  ${mediaMax("desktopSm")} {
    padding: 2vh 5vw 5vw;
  }
  ${mediaMax("tabletLandscape")} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Nav = styled.nav`
  margin-left: auto;
  ${mediaMax("tabletLandscape")} {
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin-left: 0;
    order: 0;
  }
`;
export const FooterLink = styled(Link)`
  margin-right: 5vw;
  color: #000;
  font-size: ${calculateResponsiveSize(10)};
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: ${calculateResponsiveSize(1)};
  text-transform: uppercase;
  text-decoration: none;
  ${mediaMax("desktop")} {
    font-size: 10px;
    letter-spacing: 1px;
  }
  ${mediaMax("tabletLandscape")} {
    font-size: 12px;
    margin-right: 0;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  ${mediaMax("tabletLandscape")} {
    order: 1;
    padding: 23px 0;
  }
`;

export const SocialLink = styled.a`
  margin-right: ${calculateResponsiveSize(30)};
  display: block;

  &.youtube {
    width: ${calculateResponsiveSize(21)};
    height: ${calculateResponsiveSize(14)};
    background: url(${YoutubeIcon}) center center no-repeat;
    background-size: contain;
  }
  &.facebook {
    width: ${calculateResponsiveSize(9)};
    height: ${calculateResponsiveSize(18)};
    background: url(${FacebookIcon}) center center no-repeat;
    background-size: contain;
  }
  &.instagram {
    width: ${calculateResponsiveSize(18)};
    height: ${calculateResponsiveSize(18)};
    background: url(${InstagramIcon}) center center no-repeat;
    background-size: contain;
  }
  &.linkedin {
    width: ${calculateResponsiveSize(16)};
    height: ${calculateResponsiveSize(16)};
    background: url(${LinkedinIcon}) center center no-repeat;
    background-size: contain;
  }
  ${mediaMax("desktop")} {
    margin-right: 30px;
    &.youtube {
      width: 21px;
      height: 14px;
    }
    &.facebook {
      width: 9px;
      height: 18px;
    }
    &.instagram {
      width: 18px;
      height: 18px;
    }
    &.linkedin {
      width: 16px;
      height: 16px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const Copyright = styled.p`
  color: #000;
  font-size: ${calculateResponsiveSize(10)};
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: ${calculateResponsiveSize(1)};
  text-transform: uppercase;

  ${mediaMax("desktop")} {
    font-size: 10px;
    letter-spacing: 1px;
  }
  ${mediaMax("tabletLandscape")} {
    order: 2;
    font-size: 8px;
    br {
      display: none;
    }
  }
`;
