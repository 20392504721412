import React, { useMemo } from "react";
import {
  Wrapper,
  Copyright,
  Nav,
  FooterLink,
  SocialLinks,
  SocialLink,
} from "./index.styled";
import { useStaticQuery, graphql } from "gatsby";

const Footer = () => {
  const { strapiFooterMenu, strapiSocialLink } = useStaticQuery(graphql`
    query FooterQuery {
      strapiFooterMenu {
        FooterMenuItems {
          ExternalUrl
          Title
          page {
            Slug
          }
        }
      }
      strapiSocialLink {
        SocialLink {
          Link
          Name
        }
      }
    }
  `);

  const links = strapiFooterMenu.FooterMenuItems;
  const socialLinks = strapiSocialLink.SocialLink;
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  return (
    <Wrapper>
      <Copyright>
        COPYRIGHT Ⓒ {currentYear} NAFTALI GROUP LLC.
        <br />
        ALL RIGHTS RESERVED.
      </Copyright>
      <Nav>
        {links.map((item, index) => {
          return <FooterLink data={item} key={`footer-nav-link-${index}`} />;
        })}
      </Nav>
      <SocialLinks>
        {socialLinks.map((item, index) => {
          return (
            <SocialLink
              href={item.Link}
              className={item.Name}
              key={`footer-social-link-${index}`}
              target="_blank"
            />
          );
        })}
      </SocialLinks>
    </Wrapper>
  );
};

export default Footer;
