import React from 'react'
import { gsap, ScrollTrigger } from 'helpers/gsap'

const HeaderTransition = ({
  status,
  heroSection,
  setHeaderColor,
  children,
}) => {
  const refWrapper = React.useRef(null)
  const gsapContextRef = React.useRef(null)

  React.useLayoutEffect(() => {
    if (!gsapContextRef.current && status === 'entered') {
      if (heroSection) {
        gsapContextRef.current = gsap.context(() => {
          const whiteSections = gsap.utils.toArray('.hero-section')
          whiteSections.forEach((section, index) => {
            ScrollTrigger.create({
              trigger: section,
              //markers: true,
              pin: true,
              pinSpacing: false,
              start: 'top top',
              end: '+=100%',
              onEnter: () => {
                console.dir(111)
                setHeaderColor('')
              },
              onLeave: () => {
                setHeaderColor('dark')
                console.dir(22)
              },
              onEnterBack: () => {
                setHeaderColor('')
                console.dir(33)
              },
              onLeaveBack: () => {
                setHeaderColor('dark')
                console.dir(44)
              },
            })
          })
        }, refWrapper)
      }
    } else if (
      gsapContextRef.current &&
      (status === 'exiting' || status === 'exited')
    ) {
      gsapContextRef.current.revert()
    }
    return () =>
      gsapContextRef.current ? gsapContextRef.current.revert() : null
  }, [status, heroSection, children])

  return <div ref={refWrapper}>{children}</div>
}
export default HeaderTransition
