import React, { useState } from "react";
import {
  Wrapper,
  Logo,
  Nav,
  MenuWrapper,
  HamburgerButton,
  HeaderLink,
  Overlay,
} from "./index.styled";
import Menu from "./menu";
import { useStaticQuery, graphql } from "gatsby";

const Header = ({ colorTheme, location }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const { strapiHeaderMenu, strapiSocialLink } = useStaticQuery(graphql`
    query {
      strapiHeaderMenu {
        HeaderMenuItems {
          Title
          page {
            Slug
          }
          ExternalUrl
        }
        SideMenuItems {
          ExternalUrl
          Title
          page {
            Slug
          }
        }
      }
      strapiSocialLink {
        SocialLink {
          Link
          Name
        }
      }
    }
  `);

  const headerLinks = strapiHeaderMenu.HeaderMenuItems;
  const sideMenuLinks = strapiHeaderMenu.SideMenuItems;
  const socialLinks = strapiSocialLink.SocialLink;

  return (
    <Wrapper $colorTheme={colorTheme}>
      <Logo to="/" $colorTheme={colorTheme} />
      <Nav>
        {headerLinks.map((item, index) => {
          const isActive = `/${item?.page?.Slug}/` === location.pathname;
          return (
            <HeaderLink
              className={isActive && "active"}
              data={item}
              $colorTheme={colorTheme}
              key={`header-nav-link-${index}`}
            />
          );
        })}
      </Nav>
      <HamburgerButton
        onClick={() => setIsOpenMenu(true)}
        $colorTheme={colorTheme}
      >
        <span></span>
      </HamburgerButton>
      <MenuWrapper $open={isOpenMenu}>
        <Menu
          socialLinks={socialLinks}
          headerLinks={headerLinks}
          links={sideMenuLinks}
          onClose={() => setIsOpenMenu(false)}
        />
        <Overlay $open={isOpenMenu} onClick={() => setIsOpenMenu(false)} />
      </MenuWrapper>
    </Wrapper>
  );
};

export default Header;
