import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import Link from "components/cms/link";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";
import LogoWhite from "icons/logo-white.svg";
import LogoDark from "icons/logo-dark.svg";

export const Wrapper = styled.header`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: ${calculateResponsiveSize(27)} ${calculateResponsiveSize(180)};
  background: ${({ $colorTheme }) =>
    $colorTheme === "dark" ? `#fff` : `transparent`};
  ${mediaMax("desktop")} {
    padding: 25px 5vw;
  }
  ${mediaMax("desktopSm")} {
    justify-content: space-between;
  }
`;

export const Nav = styled.nav`
  margin-left: auto;
  ${mediaMax("desktopSm")} {
    display: none;
  }
`;

export const HeaderLink = styled(Link)`
  margin-right: ${calculateResponsiveSize(55)};
  padding: ${calculateResponsiveSize(6)} 0;
  color: ${({ $colorTheme }) => ($colorTheme === "dark" ? `#000` : `#fff`)};
  font-size: ${calculateResponsiveSize(13)};
  font-weight: 500;
  line-height: ${calculateResponsiveSize(15)};
  letter-spacing: ${calculateResponsiveSize(1)};
  text-transform: uppercase;
  text-decoration: none;
  &:hover,
  &.active {
    border-bottom: ${calculateResponsiveSize(1)} solid currentColor;
  }
  ${mediaMax("desktop")} {
    font-size: 12px;
    letter-spacing: 1px;
  }
`;
export const Logo = styled(GatsbyLink)`
  display: block;
  width: ${calculateResponsiveSize(223)};
  height: ${calculateResponsiveSize(24)};

  background: ${({ $colorTheme }) =>
    $colorTheme === "dark" ? `url(${LogoDark})` : `url(${LogoWhite})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  ${mediaMax("desktopSm")} {
    width: 203px;
    height: 21px;
  }
`;
export const HamburgerButton = styled.button`
  width: ${calculateResponsiveSize(25)};
  height: ${calculateResponsiveSize(20)};
  overflow: hidden;
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;

  span {
    display: block;
    width: 100%;
    height: ${calculateResponsiveSize(2)};
    margin: ${calculateResponsiveSize(5)} 0;
    background-color: ${({ $colorTheme }) =>
      $colorTheme === "dark" ? `#000` : `#fff`};
  }
  &:before,
  &:after {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    height: ${calculateResponsiveSize(2)};
    background-color: ${({ $colorTheme }) =>
      $colorTheme === "dark" ? `#000` : `#fff`};
    transition: transform 0.4s linear;
  }
  &:hover {
    &:before {
      transform: translateX(-7px);
    }
    &:after {
      transform: translateX(7px);
    }
  }
  ${mediaMax("desktop")} {
    width: 25px;
    height: 20px;
    span {
      margin: 5px 0;
    }
    span,
    &:before,
    &:after {
      height: 2px;
    }
  }
`;
export const MenuWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #000;
  width: 30vw;
  transform: ${({ $open }) => ($open ? `translateX(0)` : `translateX(100%)`)};
  transition: transform 0.5s ease;
  ${mediaMax("desktopSm")} {
    justify-content: space-between;
    width: 100vw;
    transform: none;
    opacity: ${({ $open }) => ($open ? `1` : `0`)};
    z-index: ${({ $open }) => ($open ? `1` : `-1`)};
    pointer-events: ${({ $open }) => ($open ? `auto` : `none`)};
    transition: opacity 0.2s linear;
  }
`;
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  visibility: ${({ $open }) => ($open ? `visible` : `hidden`)};
  opacity: ${({ $open }) => ($open ? `0.3` : `0`)};
  transition: opacity 0.5s, visibility 0.5s;
`
