import React, { useEffect, useContext } from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import GlobalStyle from "styles/globalStyles";
import Header from "components/header";
import Footer from "components/footer";
import { LayoutWrapper, PageWrapper, ContentWrapper } from "./index.styled";
import { gsap, ScrollSmoother, ScrollTrigger } from "helpers/gsap";
import "styles/fonts.css";
import HeaderTransition from "../ui/header-transition";

const LayoutContent = ({
  children,
  heroSection,
  location,
  setHeaderColor = () => {},
}) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 500, exit: 100 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <HeaderTransition
              status={status}
              setHeaderColor={setHeaderColor}
              heroSection={heroSection}
            >
              <LayoutWrapper id="layoutWrapper">{children}</LayoutWrapper>
            </HeaderTransition>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  );
};

const LayoutContainer = ({ children, location, pageContext: { pageData } }) => {
  const refWrapper = React.useRef(null);
  const [headerColor, setHeaderColor] = React.useState("");
  const [heroSection, setHeroSection] = React.useState(false);

  const checkHeaderColor = () => {
    if (pageData?.Sections) {
      const isHeroSectionExists = pageData.Sections.some(
        (section) => section.strapi_component === "sections.hero"
      );
      setHeaderColor(isHeroSectionExists ? "" : "dark");
      setHeroSection(isHeroSectionExists);
    } else {
      setHeaderColor("dark");
    }
  };

  useEffect(() => {
    setHeroSection(false);
    checkHeaderColor();
  }, [location.pathname, pageData]);

  let scrollerSmoother;
  React.useLayoutEffect(() => {
    let gsapContext = gsap.context(() => {
      const sections = gsap.utils.toArray(".section-inner");
      const mediaQueryAnimation = gsap.matchMedia();

      mediaQueryAnimation.add(`(min-width: 1367px)`, () => {
        scrollerSmoother = ScrollSmoother.create({
          content: ".smooth-content",
          wrapper: ".smooth-wrapper",
          smooth: 1,
          effects: true,
        });
        // sections FadeIn for desktop
        sections.forEach((section) => {
          const sectionAnimation = gsap
            .timeline()
            .fromTo(
              section,
              { opacity: 0, y: 50 },
              { opacity: 1, y: 0, duration: 1 },
              0
            );
          ScrollTrigger.create({
            trigger: section,
            start: "top bottom-=10%",
            animation: sectionAnimation,
            toggleActions: "play none none reverse",
          });
        });
      });
      /*  ScrollTrigger.create({
        trigger: ".hero-section",
        pin: true,
        pinSpacing: false,
        start: "bottom bottom",
        end: "+=100%",
        onEnter: () => {
          console.dir(88);
          setHeaderColor("");
        },
        onLeave: () => {
          setHeaderColor("dark");
        },
        onEnterBack: () => {
          setHeaderColor("");
        },
      }); */

      mediaQueryAnimation.add(`(max-width: 1366px)`, () => {
        // sections FadeIn for mobile
        sections.forEach((section) => {
          const sectionAnimation = gsap
            .timeline()
            .fromTo(section, { opacity: 0 }, { opacity: 1, duration: 1 }, 0);
          ScrollTrigger.create({
            trigger: section,
            start: "top bottom-=10%",
            animation: sectionAnimation,
            toggleActions: "play none none reverse",
          });
        });
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "auto",
          });
        }, 100);
      });

      // ScrollTrigger.normalizeScroll({
      //   allowNestedScroll: true,
      //   lockAxis: false,
      //   type: "touch,wheel,pointer",
      // });
    }, refWrapper);

    return () => gsapContext.revert();
  }, [children /* , heroSection */]);

  useEffect(() => {
    // This don't work
    if (scrollerSmoother && location.hash) {
      setTimeout(() => {
        gsap.to(scrollerSmoother, {
          scrollTo: scrollerSmoother.offset(location.hash, "top top+=120px"),
          duration: 0,
        });
      }, 100);
    }
  }, [scrollerSmoother, location.hash]);

  return (
    <>
      <Header
        location={location}
        colorTheme={headerColor}
        className={location.pathname === "/" && "home"}
      />
      <div className="smooth-wrapper" ref={refWrapper}>
        <div className="smooth-content">
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper $heroSection={heroSection}>
              <LayoutContent
                location={location}
                setHeaderColor={setHeaderColor}
                heroSection={heroSection}
              >
                {children}
              </LayoutContent>
            </ContentWrapper>
          </PageWrapper>
          <Footer />
        </div>
      </div>
    </>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default LayoutContainer;
