import React from "react";
import {
  Wrapper,
  CloseButton,
  MenuLinks,
  MenuLink,
  SocialLinks,
  SocialLink,
} from "./index.styled";

const Menu = ({ onClose, links, socialLinks, headerLinks }) => {
  return (
    <Wrapper>
      <CloseButton onClick={onClose} />
      <MenuLinks $hideMobile={true}>
        {headerLinks.map((item, index) => {
          return (
            <MenuLink
              data={item}
              onClick={onClose}
              key={`header-menu-link-${index}`}
            />
          );
        })}
      </MenuLinks>
      <MenuLinks>
        {links.map((item, index) => {
          return (
            <MenuLink
              $mobileSeconaryLink={true}
              data={item}
              onClick={onClose}
              key={`menu-link-${index}`}
            />
          );
        })}
      </MenuLinks>
      <SocialLinks>
        {socialLinks.map((item, index) => {
          return (
            <SocialLink
              href={item.Link}
              className={item.Name}
              key={`menu-social-link-${index}`}
            />
          );
        })}
      </SocialLinks>
    </Wrapper>
  );
};

export default Menu;
